import { Box, Typography, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box
      component="footer"
      sx={{ py: 4, bgcolor: "primary.main", color: "primary.contrastText" }}
    >
      <Container maxWidth="lg">
        <Typography variant="body2" textAlign="center">
          © {new Date().getFullYear()} SHOCK BENCH. {t("footer.reserved")}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
