import { Box } from '@mui/material'
import HeroSection from './HeroSection/HeroSection'
import FeaturesSection from './FeauturesSection/FeauturesSection'
import DownloadSection from './DownloadSection/DownloadSection'
import MethodSection from './MethodSection/MethodSection'
import TeamSection from './TeamSection/TeamSection'

const Main = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <Box component="main" sx={{ flexGrow: 1 }}>
      <HeroSection />
      <FeaturesSection />
      <MethodSection />
      <TeamSection />
      <DownloadSection />
    </Box>
  </Box>
  )
}

export default Main
