import { Box, Typography } from "@mui/material";
import { useState } from "react";
import cl from "./Header.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Header = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "ru");
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };
  const menuSelected = useSelector((state: any) => state.menu.value);
  const { t }: { t: any } = useTranslation();

  if (menuSelected === "rating") {
    return <></>;
  }

  return (
    <Box className={cl.header}>
      <Box className={cl.header__text}>
        <Typography>AE SHOCK BENCH</Typography>
        <span>{t(`header.${menuSelected}`)}</span>
      </Box>
      <Box className={cl.buttons}>
        <button
          type="button"
          className={currentLanguage == "en" ? cl.active_button : cl.button}
          onClick={() => changeLanguage("en")}
        >
          EN
        </button>
        <button
          type="button"
          className={currentLanguage == "ru" ? cl.active_button : cl.button}
          onClick={() => changeLanguage("ru")}
        >
          RU
        </button>
      </Box>
    </Box>
  );
};

export default Header;
