import React, { useEffect, useState } from "react";
import cl from "./RatingCard.module.scss";
import {
  Box,
  Chip,
  Grid,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import { card_pc } from "../../../utils/types/card";
import macbook_img from "../../../../assets/img/mac_pro.png";
import mac_studio_img from "../../../../assets/img/mac_studio.png";
import pc_windows from "../../../../assets/img/pc_windows.png";
import laptop_windows from "../../../../assets/img/laptop_windows.png";
import WindowsIcon from "../../../icons/WindowsIcon";
import MacIcon from "../../../icons/MacIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VideoPreview from "./videoPreview/VideoPreview";
import FireIcon from "../../../icons/FireIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import ModalCard from "./modalCard/ModalCard";

interface CardPcProps {
  item: card_pc;
  profile: boolean;
  index: number;
}

const RatingCard: React.FC<CardPcProps> = ({ item, profile, index }) => {
  const [image, setImage] = useState<string>("");
  const [os, setOs] = useState<"mac" | "windows">("mac");
  const [formattedTime, setFormattedTime] = useState<string>("");
  const [formattedTime3D, setFormattedTime3D] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const chat_id = item.chat_id;

  //@ts-ignore
  const tg = window.Telegram?.WebApp;

  let user_id = tg?.initDataUnsafe?.user?.id;
  const id = item.id;
  const apiUrl: string | any = process.env.REACT_APP_API_DEVICES;

  const admin = 45366377;
  const admin2 = 1378371551;

  function decimalToTime(time: number) {
    const minutes = Math.floor((time * 60) / 60);
    const seconds = Math.floor((time * 60) % 60);

    return `${minutes} MIN / ${seconds} SEC`;
  }

  useEffect(() => {
    if (item.time_render) {
      let time = item.time_render;

      if (typeof time != "number") {
        time = +time;
      }

      let timeString = decimalToTime(time);
      setFormattedTime(timeString);
    }

    if (item.three_d) {
      let time = decimalToTime(item.three_d);
      setFormattedTime3D(time);
    }
    let name = item.name?.toLowerCase();

    if (
      name?.includes("macbook") ||
      (name?.includes("mac") && name?.includes("pro"))
    ) {
      setImage(macbook_img);
      setOs("mac");
    } else if (name?.includes("studio") && item.device === "🖥") {
      setImage(mac_studio_img);
      setOs("mac");
    } else if (item.device === "🖥" && !name?.includes("mac")) {
      setImage(pc_windows);
      setOs("windows");
    } else {
      setOs("windows");
      setImage(laptop_windows);
    }
  }, [item]);

  const normalise = (value: number) => ((value - 60) * 100) / (4 - 60);

  const openModalHandler = () => {
    setOpenModal((prev) => !prev);
  };

  const deleteCard = () => {
    let method = "delete";

    if (chat_id == admin || chat_id == admin2) {
      method = "delete_for_id";
    }

    const formData = new FormData();
    formData.append("chat_id", `${chat_id}`);
    formData.append("device_id", `${id}`);
    formData.append("method", `${method}`);

    axios
      .post(apiUrl, formData)
      .then((res) => {
        if (res) {
          setOpenModal(false);
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
        return;
      });
  };

  return (
    <Box className={cl.card} key={item.id}>
      <Grid container className={cl.card_main}>
        <Grid item xs={5} className={cl.card_main__img}>
          <img src={image} alt="mac" />
          <Box className={cl.card_main__img__device}>
            {os === "mac" ? <MacIcon /> : <WindowsIcon />}
          </Box>
        </Grid>
        <Grid item xs={7} className={cl.card_main__description}>
          <Typography variant="h6">
            {item.name ? item.name : "No name"}
          </Typography>
          {item.cpu && (
            <Typography>
              {item.cpu} {item.videocard && " | " + item.videocard}
            </Typography>
          )}
          {item.ram && <Typography>{item.ram} GB</Typography>}
        </Grid>
      </Grid>

      <Box>
        <LinearProgress
          className={cl.Progress}
          variant="determinate"
          value={normalise(item.time_render)}
        />
      </Box>

      <Box className={cl.card_result}>
        <Typography>SHOCK BENCH</Typography>
        <Typography>{formattedTime}</Typography>
      </Box>

      {item.three_d && (
        <Box>
          <Box>
            <LinearProgress
              className={cl.Three_d}
              variant="determinate"
              value={normalise(item.three_d)}
            />
          </Box>
          <Box className={cl.card_result}>
            <Typography>3D BENCH</Typography>
            <Typography>{formattedTime3D}</Typography>
          </Box>
        </Box>
      )}

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Chip
            color="error"
            label={`AE ${item.ae_version}.X`}
            className={cl.chip_version}
          />
        </Grid>
        {item.time_render <= 6.01 && (
          <Grid item xs={3}>
            <Chip
              color="error"
              label="BEST"
              icon={<FireIcon />}
              className={cl.chip_best}
            />
          </Grid>
        )}

        {item.watch_link && (
          <Grid item xs={3}>
            <a href={item.watch_link} target="_blank">
              <Chip
                onClick={() => console.log("link")}
                label="WATCH REVIEW"
                icon={<VisibilityIcon />}
                size="medium"
                className={cl.chip}
              />
            </a>
          </Grid>
        )}

        {profile || user_id == admin || user_id == admin2 ? (
          <Button
            variant="contained"
            className={cl.button_delete}
            onClick={openModalHandler}
          >
            <Box display="flex" alignItems="center">
              <DeleteIcon sx={{ fill: "#FF291C" }} />
            </Box>
          </Button>
        ) : null}
      </Grid>
      {item.watch_link && (
        <Box className={cl.youtube} mt={2}>
          <VideoPreview link={item.watch_link} />
        </Box>
      )}
      <ModalCard
        open={openModal}
        setOpen={setOpenModal}
        deleteCard={deleteCard}
        result={formattedTime}
      />
    </Box>
  );
};

export default RatingCard;
