import {
  Box,
  Typography,
  Container,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { Download, Cloud } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const DownloadSection = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box sx={{ py: 8, bgcolor: "background.paper" }}>
      <Container maxWidth="sm">
        <Typography variant="h2" component="h2" gutterBottom textAlign="center">
          {t('download.title')}
        </Typography>
        <Stack spacing={3} sx={{ mt: 6 }}>
            <Button
              variant="contained"
              size="large"
              target="_blank"
              href="https://github.com/yo-romlogunov/AeShockBench/releases"
              startIcon={<Download />}
              sx={{ py: 2 }}
            >
              DOWNLOAD SHOCK BENCH
            </Button>
          
          <Divider>{t('download.or')}</Divider>

            <Button
              variant="outlined"
              size="large"
              target="_blank"
              href="https://drive.google.com/drive/folders/1SyMTAnBU7GB3x8IM6ELUUsBAhpHpAEY_"
              startIcon={<Cloud />}
              sx={{ py: 2 }}
            >
              GOOGLE DRIVE
            </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default DownloadSection;
