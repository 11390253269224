import { Modal, Box, Typography, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalCard = ({ open, setOpen, deleteCard, result }: any) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            {t("modal.delete.title")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2, width: "100%" }}>
            {t("modal.delete.text")}{" "}
            {result}?
          </Typography>
          <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Button onClick={deleteCard} color="error" variant="outlined"> {t("modal.delete.success")}</Button>
              <Button onClick={() => setOpen(false)}>
                {t("modal.delete.fail")}
              </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalCard;
