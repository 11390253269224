import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cl from "./Profile.module.scss";
import RatingCard from "../rating/ratingCard/RatingCard";
import { card_pc } from "../../utils/types/card";
import ajax_get from "../../../controllers/rating/AjaxGet";
import FootIcon from "../../utils/icons/FootIcon";
import { useTranslation } from "react-i18next";
//@ts-ignore
const tg = window.Telegram?.WebApp;

const Profile = () => {
  const [items, setItems] = useState<card_pc[] | null>(null);
  const admin: string | any = process.env.REACT_APP_CHAT_ID_ADMIN;
  const { t }: { t: any } = useTranslation();

  useEffect(() => {
    let id = tg?.initDataUnsafe?.user?.id;

    if (id) {
      const res = (data: any) => {
        console.log(data);
        if (data.length) {
          setItems(data);
        }
      };

      ajax_get(`?chat_id=${id}`, res, true);
    }
  }, []);

  return (
    <Box className={cl.profile}>
      {items &&
        items.map((item: card_pc, index: number) => (
          <RatingCard item={item} key={item.id} profile={true} index={index} />
        ))}

      {!items && (
        <Box className={cl.no_found}>
          <FootIcon />
          <Typography mt={1}>{t('profile.empty')}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Profile;
