import logo from "../../../assets/img/bench_logo.png";
import cl from "./InfoView.module.scss";
import PublicIcon from "@mui/icons-material/Public";
import TelegramIcon from "@mui/icons-material/Telegram";

import { Typography, Box, Button, List, Chip, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const InfoView = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box
      sx={{ maxWidth: "100wh", maxHeight: "89vh", overflow: "auto" }}
      className={cl.info}
    >
      <Box className={cl.logo_wrapper}>
        <img src={logo} alt="SHOCK BENCH LOGO" className={cl.logo} />
        <Chip
          label={t("hero.subtitle")}
          sx={{
            backgroundColor: "#F4F5FF !important",
            border: "0.5px solid #E5E6F3",
            marginBottom: "15px",
          }}
        />
      </Box>

      <Box className={cl.first_description}>
        <Typography>{t("hero.description")}</Typography>
      </Box>

      <Box className={cl.download_block}>
        <Typography className={cl.title}>
          {t("about.download.title")}
        </Typography>
        <Box className={cl.buttons} mb={1}>
          <a href="https://github.com/yo-romlogunov/AeShockBench/releases">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#001aff", fontSize: "12px" }}
            >
              {t("about.download.shock_bench")}
            </Button>
          </a>
          <a href="https://drive.google.com/drive/folders/1SyMTAnBU7GB3x8IM6ELUUsBAhpHpAEY_">
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "black",
                fontSize: "12px",
              }}
            >
              {t("about.download.google_drive")}
            </Button>
          </a>
        </Box>
      </Box>

      <Box className={cl.person_block}>
        <Typography className={cl.title}>{t("about.team.title")}</Typography>
        <Typography mb={1}>{t("about.team.developer")}</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>{t("about.team.developer_name")}</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/miwist">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://t.me/compas_digital">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
        <Typography mb={1}>{t("about.team.designer")}</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>{t("about.team.designer_name")}</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/romlogunov_hub">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://github.com/yo-romlogunov">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
        <Typography mb={1}>{t("about.team.special_for")}</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>Rozetked</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/rozetked">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://www.youtube.com/@rozetked">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="method-section" sx={{ backgroundColor: "#f4f5ff" }}>
        <Typography variant="h6">{t("about.method.title")}</Typography>
        <Typography paragraph>{t("about.method.description")}</Typography>

        <List dense className="method-steps" sx={{paddingLeft: "0 !important"}}>
          {(t("about.method.steps", { returnObjects: true }) as string[]).map(
            (step: string, i: number) => (
              <ListItem key={i} className="method-step">
                <Typography>• {step}</Typography>
              </ListItem>
            )
          )}
        </List>
      </Box>

      <Box className="setup-section" sx={{ backgroundColor: "#f4f5ff" }}>
        <Typography variant="h6">{t("about.setup.title")}</Typography>
        <Typography paragraph>{t("about.setup.description")}</Typography>

        <List dense className="setup-steps" sx={{paddingLeft: "0 !important"}}>
          {(t("about.setup.steps", { returnObjects: true }) as string[]).map(
            (step: string, i: number) => (
              <ListItem key={i} className="setup-step">
                <Typography>
                  {i + 1}. {step}
                </Typography>
              </ListItem>
            )
          )}
        </List>
      </Box>
      <Box className={cl.bottom_buttons}>
        <a href="https://github.com/yo-romlogunov/AeShockBench/blob/main/manual.pdf">
          <Button
            variant="contained"
            sx={{ backgroundColor: "#001aff", fontSize: "12px" }}
          >
            {t("about.download.manual")}
          </Button>
        </a>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <a href="https://t.me/romlogunov">
            <TelegramIcon />
          </a>
          <Typography sx={{ maxWidth: "120px" }}>
            {t("about.help.text")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoView;
