import { Box, Typography, Container, Grid, Avatar, Chip } from "@mui/material";
import michael from "./avatars/michael.jpg";
import roman from "./avatars/roman.jpg";
import { useTranslation } from "react-i18next";

const teamMembers = [
  {
    key: "designer",
    avatar: roman,
    link: "https://t.me/romanlogunov",
  },
  {
    key: "developer",
    avatar: michael,
    link: "https://t.me/miwist",
  },
];

const TeamSection = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box sx={{ py: 8, bgcolor: "background.papper" }}>
      <Container maxWidth="md">
        <Typography variant="h2" component="h2" gutterBottom textAlign="center">
            {t('team.title')}
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4, justifyContent: "center" }}>
          {teamMembers.map((member, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(member.link, "_blank", "noopener,noreferrer")
              }
            >
              <Avatar
                src={member.avatar}
                sx={{ width: 120, height: 120, mb: 2 }}
              />
              <Typography variant="h5" component="h3" gutterBottom>
                {t(`team.${member.key}_name`)}
              </Typography>
              <Chip label={t(`team.${member.key}`)} color="primary" />
            </Grid>
          ))}
        </Grid>
        <Typography variant="body1" textAlign="center" sx={{ mt: 6 }}>
        {t('team.special')}{" "}
          <strong>
            <a href="https://rozetked.me/">Rozetked</a>
          </strong>
        </Typography>
      </Container>
    </Box>
  );
};

export default TeamSection;
