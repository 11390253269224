export interface Variant {
  text?: string;
  select?: string[];
  label?: string;
  placeholder?: string;
}

export type variants = Variant[];

export const axiosArray = [
  "version",
  "device",
  "name",
  "cpu",
  "videocard",
  "ram",
  "time",
];
