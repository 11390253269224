import { Box, Typography, Container, Stepper, Step, StepLabel, StepContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'Preparation',
  },
  {
    label: 'Execution',
  },
  {
    label: 'Analysis',
  },
];

const MethodSection = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
      <Container maxWidth="md">
        <Typography variant="h3" component="h3" gutterBottom textAlign="center">
          {t('method.title')}
        </Typography>
        <Typography variant="body1" paragraph textAlign="center" sx={{ mb: 4 }}>
        {t('method.subtitle')}
        </Typography>
        
        <Stepper orientation="vertical" sx={{ mt: 4 }}>
          {steps.map((step, index: number) => (
            <Step key={step.label} active={true}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography>{t(`method.steps.${step.label}`)}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Box>
  );
};

export default MethodSection;