import Mobile from "./components/mobile/Mobile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./components/redux";
import Main from "./components/main/Main";
import About from "./components/about/About";
import HeaderMain from "./components/header/HeaderMain";
import Footer from "./components/footer/Footer";
import "./i18n";
import "./styles/_base.scss";

//@ts-ignore
const tg = window.Telegram.WebApp;
let userId = tg?.initDataUnsafe?.user?.id;

function App() {
  return (
    <Provider store={store}>
      {userId ? (
        <Mobile />
      ) : (
        <Router>
          <HeaderMain />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </Provider>
  );
}

export default App;
