import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cl from "./RatingView.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import RatingCard from "./ratingCard/RatingCard";
import { card_pc } from "../../utils/types/card";
import axios from "axios";
import Sorting from "./sorting/Sorting";
import { useTranslation } from "react-i18next";

const RatingView: React.FC = () => {
  const { t }: { t: any } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [sortDevice, setSortDevice] = useState<string>("all");
  const [sortSystem, setSortSystem] = useState<string>("all");
  const [items, setItems] = useState<card_pc[] | null>(null);
  const [step, setStep] = useState<number>(0);
  const [filteredItems, setFilteredItems] = useState<card_pc[] | null>(items);
  const [validItems, setValidItems] = useState<card_pc[] | null>([]);
  const apiUrl = process.env.REACT_APP_API_DEVICES;

  const getItems = (device: string, step: number) => {
    let laptops: any = [];
    setLoading(true);

    axios
      .get(apiUrl + `?${device}=1&step=${step}`)
      .then((res) => {
        if (res) {
          let data = res.data;
          console.log(data);

          setLoading(false);
          setItems(data);
          setFilteredItems(data);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        return;
      });
    return laptops;
  };

  useEffect(() => {
    getItems(sortDevice, step);
  }, [sortDevice, step]);

  useEffect(() => {
    if (sortSystem === "all") {
      setFilteredItems(items);
    } else if (sortSystem === "mac") {
      const filtered = items?.filter(
        (elem) => elem.name && elem.name.toLowerCase().includes("mac")
      );
      if (filtered) {
        setFilteredItems(filtered);
      }
    } else {
      const filtered = items?.filter(
        (elem) => elem.name && !elem.name.toLowerCase().includes("mac")
      );
      if (filtered) {
        setFilteredItems(filtered);
      }
    }
  }, [sortSystem, items]);

  useEffect(() => {
    const валидные =
      filteredItems?.filter(
        (item) =>
          item.time_render !== undefined && // Убеждаемся, что time_render не undefined
          item.time_render < 9999 &&
          item.ae_version !== undefined && // Убеждаемся, что ae_version не undefined
          item.cpu !== undefined && // Убеждаемся, что cpu не undefined
          item.chat_id !== 1378371551
      ) || [];

    setValidItems(валидные);
  }, [filteredItems]);

  return (
    <Box
      className={cl.rating_view}
      sx={{ justifyContent: loading ? "center" : "flex-start" }}
    >
      <Sorting
        sortDevice={sortDevice}
        setSortDevice={setSortDevice}
        sortSystem={sortSystem}
        setSortSystem={setSortSystem}
        count={validItems?.length}
      />
      <Box className={cl.rating_list}>
        {!loading ? (
          validItems ? (
            validItems.map((item: card_pc, index: number) => (
              <RatingCard
                item={item}
                key={item.id}
                profile={false}
                index={index + 1}
              />
            ))
          ) : (
            <Box className={cl.no_found}>
              <Typography variant="h6">{t("rating.empty")}</Typography>
            </Box>
          )
        ) : (
          <Box className={cl.loader}>
            <CircularProgress />
          </Box>
        )}
        {/* {!loading && filteredItems && (
          <Box>
            <Button onClick={() => setStep((prev) => prev + 1)}>
              Показать еще
            </Button>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default RatingView;
