import { Box, Grid, Paper, Typography } from "@mui/material";
import { Speed, Telegram } from "@mui/icons-material";
import styles from "./FeaturesSection.module.scss";
import { useTranslation } from "react-i18next";

interface Feature {
  icon: React.ReactNode;
  titleKey: string;
  descKey: string;
  link: string;
  isTelegram?: boolean;
}

const FeaturesSection = () => {
  const { t }: { t: any } = useTranslation();

  const features: Feature[] = [
    {
      icon: <Speed fontSize="large" />,
      titleKey: "features.gpu.title" as const,
      descKey: "features.gpu.description" as const,
      link: "https://github.com/yo-romlogunov/AeShockBench",
    },
    {
      icon: <Telegram fontSize="large" />,
      titleKey: "features.telegram.title",
      descKey: "features.telegram.description",
      link: "https://t.me/ShockBenchBot",
    },
  ];

  return (
    <section className={styles.features}>
      <Box className={styles.container}>
        <Typography variant="h2" className={styles.heading}>
        {t("features.title")}
        </Typography>
        <Grid container spacing={4} className={styles.grid}>
          {features.map((feature: Feature, index: number) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Paper
                elevation={3}
                className={styles.featureCard}
                onClick={() =>
                  window.open(feature.link, "_blank", "noopener,noreferrer")
                }
              >
                <Box className={styles.icon}>{feature.icon}</Box>
                <Typography variant="h5">
                  {t(feature.titleKey)}
                </Typography>
                <Typography>{t(feature.descKey)}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </section>
  );
};

export default FeaturesSection;
