import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  Chip,
  Divider,
  Stack,
} from "@mui/material";
import {
  Telegram as TelegramIcon,
  Public as PublicIcon,
  Download as DownloadIcon,
  PictureAsPdf as PdfIcon,
} from "@mui/icons-material";
import logo from "../../assets/img/bench_logo.png";
import "./About.scss";

const AboutView = () => {
  const { t }: {t: any} = useTranslation();

  const teamMembers = [
    {
      role: t("about.team.developer"),
      name: t("about.team.developer_name"),
      links: [
        { icon: <TelegramIcon />, url: "https://t.me/miwist" },
        { icon: <PublicIcon />, url: "https://t.me/compas_digital" },
      ],
    },
    {
      role: t("about.team.designer"),
      name: t("about.team.designer_name"),
      links: [
        { icon: <TelegramIcon />, url: "https://t.me/romlogunov_hub" },
        { icon: <PublicIcon />, url: "https://github.com/yo-romlogunov" },
      ],
    },
    {
      role: t("about.team.special_for"),
      name: "Rozetked",
      links: [
        { icon: <TelegramIcon />, url: "https://t.me/rozetked" },
        { icon: <PublicIcon />, url: "https://www.youtube.com/@rozetked" },
      ],
    },
  ];

  return (
    <Box className="about-container">
      <Box className="logo-section">
        <img src={logo} alt="SHOCK BENCH LOGO" className="logo" />
        <Chip label="Build App - 2.1" className="version-chip" />
      </Box>

      <Typography variant="body1" paragraph className="description">
        {t("about.project_description")}
      </Typography>

      <Box className="download-section">
        <Typography variant="h6" className="section-title">
          {t("about.download.title")}
        </Typography>
        <Stack direction="row" spacing={2} className="download-buttons">
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            href="https://github.com/yo-romlogunov/AeShockBench/releases"
            className="primary-button"
          >
            {t("about.download.shock_bench")}
          </Button>
          <Button
            variant="outlined"
            startIcon={<PublicIcon />}
            href="https://drive.google.com/drive/folders/1SyMTAnBU7GB3x8IM6ELUUsBAhpHpAEY_"
            className="secondary-button"
          >
            {t("about.download.google_drive")}
          </Button>
        </Stack>
      </Box>

      <Box className="team-section">
        <Typography variant="h6" className="section-title">
          {t("about.team.title")}
        </Typography>

        {teamMembers.map((member, index) => (
          <Box key={index} className="team-member">
            <Typography variant="subtitle2" className="member-role">
              {member.role}
            </Typography>
            <Box className="member-info">
              <Typography variant="body1" className="member-name">
                {member.name}
              </Typography>
              <Stack direction="row" spacing={1}>
                {member.links.map((link, i) => (
                  <a
                    key={i}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.icon}
                  </a>
                ))}
              </Stack>
            </Box>
            {index < teamMembers.length - 1 && <Divider light />}
          </Box>
        ))}
      </Box>

      <Box className="method-section">
        <Typography variant="h6" className="section-title">
          {t("about.method.title")}
        </Typography>
        <Typography paragraph>{t("about.method.description")}</Typography>

        <List dense className="method-steps">
          {(t("about.method.steps", { returnObjects: true }) as string[]).map(
            (step: string, i: number) => (
              <ListItem key={i} className="method-step">
                <Typography>• {step}</Typography>
              </ListItem>
            )
          )}
        </List>
      </Box>

      <Box className="setup-section">
        <Typography variant="h6" className="section-title">
          {t("about.setup.title")}
        </Typography>
        <Typography paragraph>{t("about.setup.description")}</Typography>

        <List dense className="setup-steps">
          {(t("about.setup.steps", { returnObjects: true }) as string[]).map(
            (step: string, i: number) => (
              <ListItem key={i} className="setup-step">
                <Typography>
                  {i + 1}. {step}
                </Typography>
              </ListItem>
            )
          )}
        </List>
      </Box>

      <Box className="footer-section">
        <Button
          variant="contained"
          startIcon={<PdfIcon />}
          href="https://github.com/yo-romlogunov/AeShockBench/blob/main/manual.pdf"
          className="pdf-button"
        >
          {t("about.download.manual")}
        </Button>

        <Box className="help-section">
          <Typography variant="body2">{t("about.help.text")}</Typography>
          <a
            href="https://t.me/romlogunov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TelegramIcon className="help-icon" />
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutView;
