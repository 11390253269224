import { Typography } from "@mui/material";
import styles from "./HeroSection.module.scss";
import { useTranslation } from "react-i18next";

const HeroSection = () => {
  const { t }: { t: any } = useTranslation();

  return (
    <section className={styles.hero}>
      <div className={styles.container}>
        <Typography variant="h1" className={styles.title}>
          SHOCK BENCH
        </Typography>
        <Typography variant="h5" className={styles.subtitle}>
          {t("hero.subtitle")}
        </Typography>
        <Typography variant="body1" className={styles.description}>
          {t("hero.description")}
        </Typography>
      </div>
    </section>
  );
};

export default HeroSection;
